import { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { BaseService } from '@luxms/bi-core';

function disposeService(ref, ServiceClass: any, onUpdate) {
  if (!ref.current) return;

  // инстанс класса уже есть - надо уничтожить
  ref.current.unsubscribe(onUpdate);

  if (!ServiceClass.getInstance) {
    ref.current.release();
  }
  ref.current = null;
}

function recreateService(ref, ServiceClass: any, onUpdate, args: any[]) {
  try {
    disposeService(ref, ServiceClass, onUpdate);

    if (ServiceClass.getInstance) {
      ref.current = ServiceClass.getInstance();
    } else if (ServiceClass.createInstance) {
      ref.current = ServiceClass.createInstance(...args);
    } else {
      ref.current = new ServiceClass(...args);
    }
    ref.current.subscribeUpdates(onUpdate);
  } catch (err) {
    // тут бывает какая-то плавающая ошибка
    debugger;
  }
}

// Загружает сервис и по его обновлениям меняет state
export function useServiceItself<S>(ServiceClass: typeof S, ...args: any): S {
  const serviceRef = useRef(null);                                                                  // инстанс сервиса
  const creationalArgs = useRef<any[]>([]);                                                         // аргументы при создания
  const argsChanged = !isEqual(creationalArgs.current, args);                                       // поменялись ли аргументы? быть может, достаточно shallowCompare?
  const onUpdateRef = useRef((model) => {                                                           // сохраняем callback в ref, чтоб каждый раз функция не пересоздавалась
    setModel(model);
  });

  if (!serviceRef.current || argsChanged) {                                                         // нужно создать/пересоздать сервис
    creationalArgs.current = args;
    recreateService(serviceRef, ServiceClass, onUpdateRef.current, creationalArgs.current);
  }

  const [model, setModel] = useState(serviceRef.current!.getModel());

  useEffect(() => {
    if (!serviceRef.current) {                                                                      // если компонент размаунтился и заманутился снова
      recreateService(serviceRef, ServiceClass, onUpdateRef.current, creationalArgs.current);
    }

    return () => {
      disposeService(serviceRef, ServiceClass, onUpdateRef.current);
    };
  }, []);

  return serviceRef.current;
}


export function useService<S extends BaseService<any>>(ServiceClass: typeof S, ...args: any): ReturnType<S['getModel']> {
  const service = useServiceItself<S>(ServiceClass, ...args);
  return service.getModel();
}

export default useService;
