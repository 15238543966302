import React from 'react';
import {IRootSegment} from '../../plugins/plugins-model';
import {Menu} from '@luxms/bi-face';
import Logo from '../../../srx/views/app/Menu/Logo';
import IfICan from '../helpers/IfICan';


interface IRootMenuProp {
  tabs: IRootSegment[];
  activeTabIndex: number | null;
  activeChildIndex: number | null;
}

export class RootMenu extends React.Component<IRootMenuProp, any> {
  public constructor(props) {
    super(props);
  }

  private _renderMenuItem = (item: IRootSegment, idx: number): any => {
    const {activeTabIndex, activeChildIndex} = this.props;

    let icon = item?.getIcon?.() ?? null;
    if (icon) icon = React.createElement(icon, null);
    return ( !!item.children ?
        <Menu.SubList title={item.title} key={item.key} icon={icon} treeLevel={0} >
          {item.children.map((child, childIdx) =>
            <Menu.Item active={idx === activeTabIndex && childIdx === activeChildIndex}
                       key={child.key}
                       title={child.title}
                       href={child.url} >
              {child.title}
            </Menu.Item>)}
        </Menu.SubList> :
      <Menu.Item active={idx === activeTabIndex} icon={icon} key={item.key} title={item.title} href={item.url}>
        {item.title}
      </Menu.Item>);
  }

  public render() {
    const {tabs = [], activeTabIndex = null} = this.props;
    if (activeTabIndex === null) return null;

    return (
      <Menu className="Root_Menu Root__Menu" onExpanded={() => null} onCompact={() => null} onHidden={() => null}>
        <Menu.Header>
          <a href="/#/"><Logo/></a>
        </Menu.Header>
        <div className="Root_Menu_Content BIScroll">
          <Menu.List>
            {tabs.map(this._renderMenuItem)}

            {/*<IfICan oneOf={['L rbac.domain_group_rbac9_group_maps']}>
              <Menu.SubList title="Администрирование!!!!!!" icon={null} treeLevel={0} >
                <Menu.Item active={false} icon={null} title="Сайтовые роли" href="#/adm/grusers">
                  Группы и пользователи
                </Menu.Item>
                <Menu.Item active={false} icon={null} title="Сайтовые роли" href="#/adm/RbacSiteRoles">
                  Сайтовые роли
                </Menu.Item>
              </Menu.SubList>
            </IfICan>*/}

          </Menu.List>
        </div>
        <Menu.Footer>
          <Menu.Toolkit>
            <Menu.ToggleControls>
              <Menu.Control mark="collapseMenu"/>
              <Menu.Control mark="expandMenu"/>
            </Menu.ToggleControls>
          </Menu.Toolkit>
        </Menu.Footer>
      </Menu>
    );
  }
}