import * as L from 'leaflet';
import { parse as wktParse } from 'wellknown';
import axios from 'axios';
import {IConfigHelper, IDatasetModel} from '../../services/ds/types';
import {IKoobMeasure, IMetric, ISubspace, IValue} from '../../defs/bi';
import {data_engine} from '../../data-manip/data-manip';
import {KoobFiltersService} from '../../services/koob/KoobFiltersService';
import {SubspacePtr} from '../../services/ds/ds-helpers';
import {
  DataProviderCache,
  IMapPointsRenderer,
  TitleMarker,
} from './clustering';
import {AppConfig} from '@luxms/bi-core';
import { isNullVector, matrixMax, vectorMax, vectorMin } from '../../data-manip/data-utils';
import {$eid} from '../../libs/imdas/list';
import { coloring } from '../../utils/utils';
import { MapFillColorizer } from './charts';
import formatNumberWithString from 'format-number-with-string';
import './KoobMF.scss';
import ReactDOM from 'react-dom';
import React, {RefObject} from 'react';
import {Stoplights} from '../../config/Stoplights';
import Tick from './layers/Tick';
import {__buildTicks} from './layers/MapFillLayerDialogR';
import {KoobSubspaceAsyncService} from '../../services/koob/KoobSubspaceAsyncService';


interface ICustomAreaRenderer {
  evaluate(customAreas: any[], subspace: ISubspace): any;
  evaluateMapPosition?(customAreas: any[]): void;
}

interface IMapAreaFactory {
  createCustomAreaGroup(): L.FeatureGroup<L.Layer>;
  createTitleGroup(): L.FeatureGroup<L.Layer>;
  createMapArea(data: any): AreaElement;
  createCustomAreaElement(data: any): AreaElement;
  createRenderer(map: L.Map): any;
}

export class DisplayAreasRendererKoob implements IMapPointsRenderer {
  protected _dataset: IDatasetModel;
  protected map: L.Map;

  public constructor(dataset: IDatasetModel, map: L.Map, public displayLocationsOnlyAtLevels?: number) {
    this._dataset = dataset;
    this.map = map;
  }

  public evaluate(customAreas: any[], subspace: ISubspace): any {
    let areas: any[] = customAreas;
    return {areas: areas};
  }

  public evaluateMapPosition(customAreas: any[]): void {
    if (customAreas.length === 0) {
      return;
    }
    if (customAreas.length === 1) {
      this.map.setView(customAreas[0].latlng, this.map.getZoom());
      return;
    }

    let lats: number[] = customAreas.map(l => l.latlng.lat);
    let lngs: number[] = customAreas.map(l => l.latlng.lng);

    const minLat: number = Math.min.apply(Math, lats), maxLat: number = Math.max.apply(Math, lats);
    const minLng: number = Math.min.apply(Math, lngs), maxLng: number = Math.max.apply(Math, lngs);

    const dLat: number = maxLat - minLat;
    const dLng: number = maxLng - minLng;

    window.setTimeout(() => {
      if (!this.map) return;
    }, 0);
  }
}

function getArrayCoordinates(elem: []) {
  let result = [];
  for (var i = 0; i < elem.length; i++) {
    if (Array.isArray(elem[i][0])) {
      result = result.concat(getArrayCoordinates(elem[i]));
    } else {
      result.push(elem[i]);
    }
  }
  return result;
}

export const TOTAL_WIDTH: number = 330;
export const TOTAL_LR_MARGIN: number = 0;

export class BaseAreaLayer {
  public id: string;
  public title: string;
  public area: any;            // L.Marker;
  public $container = null;
  public visible: boolean = true;
  public color: string = null;
  private _data: any;

  public constructor(data: any, dataset: IDatasetModel) {
    this._data = data;
    this.title = data.title || '';
  }

  public initCustomAreaLayer(data: any, z: number = 0) {
    const createPopup = () => {

      let div = document.createElement('div');
      div.classList.add('AreaPopup');

      let div_header = document.createElement('div');
      div_header.classList.add('AreaPopup__Header');
      div_header.innerHTML = `${data.title}`;

      let div_body = document.createElement('div');
      div_body.classList.add('AreaPopup__Body');
      div_body.innerHTML = `${data.subtitle ? data.subtitle + ':&nbsp; ' : ''}`;

      let span = document.createElement('div');
      span.classList.add('AreaPopup__Value');

      span.innerHTML = data.value ? `${data.formatted_value ? data.formatted_value : data.value}&nbsp;${data.unit ? data.unit.axis_title : ''}` : '';

      div_body.appendChild(span);
      div.appendChild(div_header);
      div.appendChild(div_body);

      return div;
    };

    const selectFeature = (e) => {
      const layer = e.target;

      layer.setStyle({
        weight: 2,
        color: '#b91c20',
        dashArray: '',
        fillOpacity: 0.7,
      });

      let legend = layer.feature.properties.legend || null;
      if (legend) {
        legend.update({
          color: layer.feature.properties.color,
          orderInd: layer.feature.properties.id,
          percent: layer.feature.properties.percent,
          title: layer.feature.properties.title,
          value: layer.feature.properties.value,
        });
      }

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
    };

    const geojsonFeature = {
      type: 'Feature' as const,
      properties: data,
      geometry: data.geo_json,
    };

    const resetHighlight = (e, layer, area) => {
      area.resetStyle(e.target);
      let legend = layer.feature.properties.legend || null;
      if (legend) legend.update(null);
    };

    const area: any = L.geoJSON(geojsonFeature, {
      style: (feature) => {
        const noDataColor = 'white';
        const hasData = true;                                                                       // нужно ли учитывать, что данных может не быть?
        const isLine: boolean = (feature.geometry.type === 'LineString' || feature.geometry.type == 'MultiLineString');
        const lineColor: string = hasData ? (isLine ? data.color : 'white') : noDataColor;
        const dashArray: string = isLine ? (hasData ? null : '12') : '3';

        return {
          opacity: 1,
          color: lineColor,
          fillColor: data.color,
          fillOpacity: (hasData ? 0.7 : 0),
          weight: isLine ? 10 : 1,
          dashArray,
        };
      },
      onEachFeature: (feature, layer) => {
          layer.bindPopup(createPopup);

          layer.on({
            mouseover: selectFeature,
          //  click: layer.bindPopup(createPopup),
          });
          layer.on('mouseout', (e) => resetHighlight(e, layer, area));
        },
    });

    this.area = area;
  }

  public setColor(value: string): void {
    this.color = value;

    if (this.color == '#ff0000') {
      this.area.setval(1);
    } else {
      this.area.setval(0);
    }

    if (this.$container && this.color != null) this.applyColorInternal();
  }

  public setMetric(m: any): void {
    this.area.metric = m;
    this.area.id = m ? m.id : null;
  }

  public setValue(val: IValue): void {
    this.area.setval(val);
  }

  public getValue(): IValue {
    return this.area.getval();
  }

  public redraw(visible: boolean): void {
    if (null == this.$container) return;
    if (visible) {
      this.$container.parent().removeClass('hidden-marker');
    } else {
      this.$container.parent().addClass('hidden-marker');
    }
  }

  // abstract protected
  public applyColorInternal() {
    //
  }
}

export class CustomAreaLayer extends BaseAreaLayer {
  public constructor(element: any, dataset: IDatasetModel) {
    super(element, dataset);
    this.id = 'map-area-' + element.id;

    const onClickAction: string = 'openObjectCard';   // dataset.config.getStringValue('map.pin.onClick'); TODO: get real config value
    let cursor: string = 'pointer';
    if (onClickAction === 'openObjectCard') {
      cursor = 'default';
    }
    if (element) this.initCustomAreaLayer(element);
  }

  public applyColorInternal() {
    let container = document.getElementById(`map-area-${this.$container.id}`) || null;
    if (container) {
      let body = container.querySelector('path');
      if (body) body.setAttribute('fill', this.color);
    }
  }
}

export class DefaultAreaMapFactory implements IMapAreaFactory {
  private _dataset: IDatasetModel;

  constructor(dataset: IDatasetModel) {
    this._dataset = dataset;
  }

  public createCustomAreaGroup(): L.FeatureGroup<L.Layer> {
    return L.featureGroup();
  }

  public createTitleGroup(): L.FeatureGroup<L.Layer> {
    return L.featureGroup();
  }

  public createMapArea(data: any): AreaElement {
    return new AreaElement(data, this._dataset, this);
  }

  public createCustomAreaElement(data: any): any {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const className = configHelper.getValue('map.customAreaClass', 'CustomArea'); // TODO CLASS
    switch (className) {
      case 'CustomArea':
        return new CustomAreaLayer(data, this._dataset);
      default:
        throw new Error('Undefined classname to create area');
    }
  }

  public createRenderer(map: L.Map, subspace?: ISubspace): any {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();
    const displayLevel: number = configHelper.getIntValue('map.displayLocationsOnlyAtLevels', -1);

    if (subspace && subspace.koob) {
      return new DisplayAreasRendererKoob(this._dataset, map, displayLevel);
    }
  }
}

class AreaElement {
  public id: number | string;
  public title: string;
  public nodata: boolean = true;
  public data: any;

  public latlng: L.LatLng;
  public titleMarker: TitleMarker;
  public customArea: any;

  public factory: IMapAreaFactory;

  public visible: boolean = false;

  private _dataset: IDatasetModel;
  public zoom?: number;

  public constructor(data: any, dataset: IDatasetModel, factory: IMapAreaFactory) {
    this.data = data;
    this._dataset = dataset;

    this.id = data.id;
    this.title = data.title;

    this.factory = factory;

    this.initArea();
  }

  public initArea() {
    this.customArea = this.factory.createCustomAreaElement(this.data);
  }

  public setData(parameters: any[], vector: IValue[], domain: number[]) {
    // nodata if all values in the vector are NULL
    this.nodata = isNullVector(vector);
    if (this.nodata) {
      if (this.customArea) this.customArea.redraw(false);
      if (this.titleMarker) this.titleMarker.redraw(true);
    } else {
      if (this.customArea) this.customArea.setData(parameters, vector, domain);
      this.redraw();
    }
  }

  public setAreaColor(color: string): void {
    this.customArea.setColor(color);
  }

  public hide() {
    if (!this.visible) return;
    this.visible = false;
    this.redraw();
  }

  public show() {
    if (this.visible) return;
    this.visible = true;
    this.redraw();
  }

  public onAdd(titleGroup: L.FeatureGroup<L.Layer>, areaGroup: L.FeatureGroup<L.Layer>) {
    if (this.customArea) areaGroup.addLayer(this.customArea.area);
    if (this.titleMarker) titleGroup.addLayer(this.titleMarker.lmarker);
    this.redraw();
  }

  public onRemove() {
    //
  }

  private redraw() {
    if (this.customArea) this.customArea.redraw(this.visible);
    if (this.titleMarker) this.titleMarker.redraw(this.visible);
  }

}

interface IColorItem {
  color: string;
  orderInd: number;
  value: string | number;
}

interface IStopLightLegend {
  colors: IColorItem[];
  min: number;
  max: number;
  updateLegend: (data: any) => void;
  id?: string | number;
  ticks: Tick[];
}

export const StopLightLegend = (props: IStopLightLegend) => {
  const container: RefObject<HTMLDivElement> = React.createRef();
  const selectorContainer: RefObject<HTMLDivElement> = React.createRef();

  let colors = (props.colors.length) ? props.colors.map((i) => `${i.color}`) :  [];
  let colorString = colors.join(', ');

  let currentActive = React.useRef(null);
  const [activeValue, setActive] = React.useState(null);

  React.useEffect(() => {
    if (props.updateLegend) props.updateLegend((data: any) => setActive(data));
  }, []);

  let ticks = props.ticks || [];

  React.useEffect(() => {
    if (container.current) {
      while (container.current.lastChild && container.current.lastChild.classList.contains('Tick')) {
        container.current.removeChild(container.current.lastChild);
      }

      ticks.map((t: Tick): void => {
        if (t.isVisible) {
          let div = document.createElement('div');
          div.classList.add('Tick');
          div.innerHTML = t.text;
          div.style.left = `calc(${t.tickLeft}% - 4px)`;
          container.current.appendChild(div);
        }
      });
    }
  }, [container]);

  React.useEffect( () => {
    if (!currentActive.current || currentActive.current !== activeValue) {
      let active: number = null;

      if (activeValue) {
         active = (activeValue.value - props.min) / ((props.max - props.min) / 100);
      }

      if (selectorContainer.current) {
        if (active || active === 0) {
          selectorContainer.current.style.left = `calc(${active}% - 5px)`;
          selectorContainer.current.style.opacity = `1`;
        } else {
          selectorContainer.current.style.left = `calc(${0}% - 5px)`;
          selectorContainer.current.style.opacity = `0`;
        }
      }
      currentActive.current = activeValue;
    }
  }, [activeValue]);

  return(<div className={'StopLightLegend'}>
    <div ref={container}
         className={'StopLight__Wrapper'} style={{background: `linear-gradient(to right, ${colorString})`}}>
      <div ref={selectorContainer} className={'LegendSelectionMarker'}> </div>
    </div>
  </div>);
};

interface ICustomDiscreteLegend {
  elements: any[];
}

export const CustomDiscreteLegend = (props: ICustomDiscreteLegend) => {
  const container: RefObject<HTMLDivElement> = React.createRef();

  const elements = props.elements || [];
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    const items = elements.map((el: any, ind: number) => {
      const color: string = (el.color) ? el.color : '#000000';
      const title: string = (el.title) ? el.title : (el.name) ? el.name : '';

      return(<div key={`stopLight-${ind}-${title}`} className={'DiscreteLegend__Item'}>
        <div className={'DiscreteLegend__ColorItem'}><div className={'DiscreteLegend__ColorPalette'} style={{background: color}}> </div></div>
        <div className={'DiscreteLegend__TitleItem'}>{title}</div>
      </div>);
    });
    setItems(items);
  }, [elements]);

  return(<div ref={container} className={'DiscreteLegend'}>{(items) ? items.map((i) => i) : null}</div>);
};

export class KoobAreaLayer extends L.Layer<any, any> {
  protected _map: L.Map = null;
  private _areaLayers: AreaElement[] = [];

  private _customAreaGroup: L.FeatureGroup<L.Layer> = null;
  private _titleGroup: L.FeatureGroup<L.Layer> = null;

  private _factory: DefaultAreaMapFactory; // IMapFactory;

  private _dataset: IDatasetModel;
  private _subspace: ISubspace;
  private _dataProvider: data_engine.IDataProvider;
  private _renderer: IMapPointsRenderer = null;

  private _cfg = null;

  private areasData: any[]; // список регионов с вкт

  // @ts-ignore
  public legend: L.control = null;
  private _setCurrentArea: (data: any) => void | null = null;

  public constructor(data: {
    dataset: IDatasetModel;
    subspace: ISubspace;
    cfg: any;
    map: L.Map;
  }) {
    super(data);
    this._dataset = data.dataset;
    this._subspace = data.subspace;
    this._dataProvider = this._dataset.getDataProvider();

    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    this._factory = new DefaultAreaMapFactory(this._dataset);
    this.areasData = [];

    this._createLayers();

    this._cfg = data.cfg;
    let rawDataSource: any = {...this._cfg.getRaw().dataSource};

    if (this._cfg.hasOption('fillAreasByData') || this._cfg.hasOption('FillAreasByData')) {
      this._requestCustomData();
    }

    this._map = data.map;

    this._setCurrentArea = null;

    if (!!this._map && (this._cfg.hasOption('fillLegend') || this._cfg.hasOption('FillLegend'))) {
      this._setLegend();
    }

  }

  private _setLegend = () => {
    let legendPositon: 'bottomright' | 'bottomleft' | 'topright' | 'topleft' = this._cfg.rawDataSource?.display?.legendPosition || 'bottomright';

    let oldLegend = L.DomUtil.get('mapLegend');
    if (!!oldLegend) L.DomUtil.remove(oldLegend);

    if (this.legend || !this._map) return;
    this.legend = L.control({position: legendPositon});

    this.legend.onAdd = (map: any) => {
      const div = L.DomUtil.create('div', 'LegendInfo legendBlock');
      div.setAttribute('id', 'mapLegend');
      const discrete = L.DomUtil.create('div', 'DiscreteLegend legend1');
      const gradient = L.DomUtil.create('div', 'GradientLegend legend2');


      div.appendChild(discrete);
      div.appendChild(gradient);

      this.legend.update();
      return div;
    };

    this.legend.update = (data: any) => {
      if (this._setCurrentArea) this._setCurrentArea(data);
    };

    this.legend.addTo(this._map);
  }

  private async _requestCustomData() {
    let rawOptions: any = {...this._cfg.getRaw()};

    let pathToMapApi = `glossary.russia_region_borders`; ///////
    if (rawOptions.pathToMapApi && rawOptions.pathToMapApi.length) pathToMapApi = rawOptions.pathToMapApi;

    axios.get(AppConfig.fixRequestUrl(`/api/db/${pathToMapApi}`))
      .then((resp) => {
      this.areasData = resp.data || [];

      this._getDataFromSubspacePtr();
    }).catch(function (error) {
     console.log(error);
    });
  }

  private async _getDataFromSubspacePtr() {
    this.onRemove();
    this._createLayers();

    let rawDataSource: any = {...this._cfg.getRaw().dataSource};

    let dataSourceFilters = this._cfg.getRaw().dataSource.filters || {};
    let filters: any = Array.isArray(dataSourceFilters) ? Object.fromEntries(dataSourceFilters.map((d) => [d, true])) : {...dataSourceFilters};

    const koobFiltersModel = KoobFiltersService.getInstance().getModel();
    Object.keys(filters).forEach(key => {
      if (filters[key] === true) filters[key] = koobFiltersModel.filters[key];
      if (filters[key] === undefined) delete filters[key];
    });

    rawDataSource.filters = filters;

    const subpacePtr = new SubspacePtr(rawDataSource);
    const subspaceService = new KoobSubspaceAsyncService(this._cfg.getDataset().schemaName, subpacePtr);

    await subspaceService.whenReady();
    const {subspace} = subspaceService.getModel();
    const data = await this._dataProvider.getMatrixYX(subspace);

    this.setKoobDataToPoints(data);
  }

  public setData(data: any[], metrics: IMetric[], matrix: IValue[][]) {
    const max: number = matrixMax(matrix);
    const domain = [0, max];
    data.forEach((item, i) => {
      $eid(this._areaLayers, item.id).setData(metrics, matrix[i], domain);
    });
  }

  private updateLegend = (setParam?: (data: any) => void): void => {
    if (setParam) this._setCurrentArea = setParam;
  }

  public setKoobDataToPoints = (data: any) => {
    let subspace = this._subspace;
    let filtered_data: any[] = [];
    let regions = this.areasData;

    if (data.length) {
      let fullData = data.flat() || [];

      let min = vectorMin(fullData);
      let max = vectorMax(fullData);

      let s = null;

      let points = [...fullData] || [];
      points.sort((a, b) => a - b);

      let fillOption = 'FillLegend';
      // TODO убрать ту что с маленькой буквы
      if (this._cfg.hasOption(fillOption) || this._cfg.hasOption(`fillOption`)) {
          s = Stoplights.createWithVector(this._cfg.getRaw().display?.stoplight, points);

          let colors = [];
          const N = 330;
          for (let i = 0; i <= N; i++) {
            let valueForThisPoint = min * (1 - i / N)  + max * (i / N);
            let roundedValue = Number(valueForThisPoint.toFixed(10));
            let colorForThisPoint = s.getColor(null, roundedValue);
            colors.push({
              value: valueForThisPoint,
              color: colorForThisPoint,
              orderInd: i,
            });
          }

          const range: any = [min, max];

          let ticks: any[] = __buildTicks(
            range,
            TOTAL_WIDTH - 2 * TOTAL_LR_MARGIN,    // width
            -TOTAL_LR_MARGIN,                     // minx
            TOTAL_WIDTH - TOTAL_LR_MARGIN);       // maxx

          if (this.legend) {
            let stoplights = s.map((i) => {
              return i;
            });

            if (stoplights.length) {
              let div2 = document.querySelector('.legend1');

              if (div2) {
                ReactDOM.render(<CustomDiscreteLegend
                  elements={stoplights}
                />, div2);
              }
            }

            let div1  = document.querySelector('.legend2');
            if (colors.length) {

              if (!!div1 ) {
                div1.classList.remove('inactiveLegend');
                ReactDOM.render(
                  <StopLightLegend
                    id={'legendary'}
                    colors={colors}
                    ticks={ticks}
                    min={min}
                    max={max}
                    updateLegend={this.updateLegend}
                  />,
                  div1,
                );
              }
            } else {
              div1.classList.add('inactiveLegend');
            }
          }
      }

      const colorMin = coloring.make('#ffe8e8').toRGB();
      const colorMax = coloring.make('#ff0000').toRGB();
      const colorResolver = new MapFillColorizer(min, max, [colorMin.r, colorMin.g, colorMin.b], [colorMax.r, colorMax.g, colorMax.b]);

      // [regions, measures]

      let dimId = subspace.dimensions[0]?.id || '';
      let measId = subspace.measures[0]?.id || '';

      let axisIter = (subspace.xs[0] && subspace?.xs[0]?.config?.[dimId]) ? [subspace.xs, subspace.ys] : [subspace.ys, subspace.xs];

//  dimensions for
      // region ident-r i
      for (let i = 0; i <= axisIter[0].length; ++i) {
        let item = null;

        // measure item
        subspace.measures.map((measure: any, idx: number) => {

          console.log(i, points, points[i]);
          if (points[i] && points[i] !== null) {
            if (idx === 0) item = {};

            let format = this._cfg.getFormat(measure?.id);
            if (item == null) item = {};

            const dimension = axisIter[0][i];

            item[dimension?.formula[0] || 'region_id'] = dimension?.id;

            dimension?.formula.map((for_item: string, index) => {
              item[for_item] = dimension?.ids[index];
            });

            item[measure.id] = (data[i][0]) ? data[i][0] : '';

            item['subtitle'] = measure.title;
            item['value'] = (data[i][0]) ? data[i][0] : null;
            item['formatted_value'] = (format) ? formatNumberWithString(item.value, format) : item.value;
            let roundedValue = item.value ? Number(item.value.toFixed(10)) : '';
            item['color'] = (s) ? s.getColor(null, roundedValue) : colorResolver.getColor(null, item.value);
            item['unit'] = measure.unit ? measure.unit : '';
          }

          if (item && item['region_id'] && Number(item['region_id']) > 0) {
            let area = regions.find((a) => String(a.id) === String(item['region_id']));

            if (area) {
              let custom_area = {
                ...area,
              };

              let geoJSON: any = null;
              try {
                geoJSON = area.wkt ? wktParse(area.wkt) : null;
              } catch (err) {
                console.error(err);
                console.warn(`Invalid wkt: "${area.wkt}" for spatial`);
              }

              custom_area.geo_json = geoJSON;
              custom_area.legend = this.legend;
              item['area'] = custom_area;

              if (item) filtered_data.push(item);
            }
          }
        });
      }
    }

    this._areaLayers = filtered_data.map((d: any) => {
      let item: any = null;
      item = {...d, ...d.area};
      if (item) {
        const ca: any = this._factory.createMapArea(item);
        if (ca) return ca;
      }
    });

    if (this._customAreaGroup) {
      // reset current for redraw
      this._map.removeLayer(this._customAreaGroup);
      this._customAreaGroup = null;
      this._customAreaGroup = this._factory.createCustomAreaGroup();
    }

    this.onAdd(this._map);
  }

  private _createLayers = () => {
    this._titleGroup = this._factory.createTitleGroup();
    this._customAreaGroup = this._factory.createCustomAreaGroup();
  }

  public setChartType(value: string): void {

  }

  public onAdd(map: L.Map): this {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    this._map = map;

    map.addLayer(this._customAreaGroup);
    map.addLayer(this._titleGroup);

    this._customAreaGroup.on('click', (e) => {
      this._handleChartClick(e);
    });

    if (this._subspace.koob) {

      this._areaLayers.forEach((ar: AreaElement) => {
        ar.onAdd(this._titleGroup, this._customAreaGroup);
       // mp.setAreaColor(cfgMapAreaColor); COLOR
      });
    } else {
      this._areaLayers.forEach((ar: any) => {
        ar.onAdd(this._titleGroup, this._customAreaGroup);
       //  mp.setAreaColor(cfgMapAreaColor);
      });
    }

    this.redraw();
    return this;
  }

  public onRemove(): this {
    if (this._customAreaGroup) {
      this._map.removeLayer(this._customAreaGroup);
      this._customAreaGroup = null;
    }
    return this;
  }

  public redraw(doMoveMap: boolean = true): void {
    const configHelper: IConfigHelper = this._dataset.getConfigHelper();

    if (!this._renderer) {
      this._renderer = this._factory.createRenderer(this._map, this._subspace);
    }
    const result: any = this._renderer.evaluate(this._areaLayers, this._subspace);
  }

  private _handleChartClick = (e: any) => {

  }

  public setKoobAxes = (subspace: ISubspace) => {
    if (this._subspace !== subspace) {
      this._subspace = subspace;
      this._requestCustomData();
    }

    if ((this._dataProvider as DataProviderCache).invalidate) {
      (this._dataProvider as DataProviderCache).invalidate();
    }

    this.redraw(!!subspace);
  }

}

