/// <reference path="../../defs/bi.d.ts" />

/**
 *   Dataset shell
 */

import React, {useRef, useState} from 'react';
import './DsShell.scss';
import cn from 'classnames';
import { IDsShellVM } from '../../view-controllers/DsShellVC';
import {AppConfig, extractErrorMessage, repo, srv, UrlState} from '@luxms/bi-core';
import {lang, MessageHub, ruKbdToEng, XLSX_MIME_TYPE} from '../../utils/utils';
import { DsShellHeader } from './DsShellHeader';
import { DsShellLeftPane } from './DsShellLeftPane';
import { DsShellTitle } from './DsShellTitle';
import { DsShellError } from './DsShellError';
import debounce from 'lodash/debounce';
import Strap from '@luxms/bi-face/Strap';
import { useServiceItself } from '../useService';
import EditModeVC from '../../view-controllers/EditModeVC';
import { Button } from '@luxms/bi-face';
import {IDashboard} from '../../services/ds/types';
import {TransactionEntitiesService} from '../../services/TransactionEntitiesService';
import IRawDashlet = repo.ds.IRawDashlet;
import LoadFromResources from '../components/LoadFromResources';
import SetTheme from '../components/SetTheme';
import ExcelCatch from '../components/koob/ExcelCatch';
import {DsStateService1} from '../../services/ds/DsStateService1';


const PanelMetrics = React.lazy(() => import('../panels/PanelMetrics/PanelMetrics'));
const PanelLocations = React.lazy(() => import('../panels/PanelLocations/PanelLocations'));
const PanelPeriods = React.lazy(() => import ('../panels/PanelPeriods/PanelPeriods'));

interface IDraggableAProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  direction: 'vertical' | 'horizontal';
}

const DragableA = React.memo((props: IDraggableAProps) => {

  const myRef = useRef<HTMLAnchorElement>();
  const [draggingToggle, setDraggingToggle] = useState(false);
  const [previousTop, setPreviousTop] = useState(0);
  const [previousLeft, setPreviousLeft] = useState(0);
  let [moved, setMoved] = useState(false);

  const positionDelta = (event) => {
    const top = event.pageY;
    const left = event.pageX;
    const delta = {
      top: top - previousTop,
      left: left - previousLeft,
    };
    setPreviousTop(top);
    setPreviousLeft(left);
    return delta;
  };

  const onPointerDown = (event) => {
    setMoved(false);
    event.target.setPointerCapture(event.pointerId);
    positionDelta(event);
    setDraggingToggle(true);
  };

  const onPointerMove = (event) => {

    if (!draggingToggle) {
      return;
    }
    const {top} = positionDelta(event);
    const {left} = positionDelta(event);
    const parent = myRef.current!.parentElement;
    const clamp = (v, min, max) => Math.max(min, Math.min(max, v));

    if (props.direction === 'vertical') {
      if (top > 0.6 || top < -0.6) setMoved(true);
      const elHeight = parseFloat(getComputedStyle(myRef.current).height)  / 2;
      const elHPercent = elHeight / parent.offsetHeight * 100;
      const currentPxTop = parseFloat(getComputedStyle(myRef.current).top);
      myRef.current!.style.top  = clamp((currentPxTop + top) / parent.offsetHeight * 100, elHPercent, 100 - elHPercent * 1.5) + '%';
    }

    if (props.direction === 'horizontal') {
      if (left > 0.6 || left < -0.6) setMoved(true);
      const elWidth = parseFloat(getComputedStyle(myRef.current).width)  / 2;
      const elWPercent = elWidth / parent.offsetWidth * 100;
      const currentPxLeft = parseFloat(getComputedStyle(myRef.current).left);
      myRef.current!.style.left = clamp((currentPxLeft + left) / parent.offsetWidth * 100, elWPercent, 100 - elWPercent) + '%';
    }

    setDraggingToggle(true);
  };

  const onPointerUp = (event) => {
    setDraggingToggle(false);
  };

  return <a {...props}
            ref={myRef}
            onClick={moved ? null : props.onClick}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}>
    {props.children}
  </a>;
});


const DsShell__Header = ({schema_name, dsTitle, dsDescription, dsUrl}: {schema_name: string, dsTitle: string, dsDescription: string, dsUrl: string}) => {
  const editModeVC = useServiceItself<EditModeVC>(EditModeVC);
  const editMode = editModeVC.getModel().editMode;

  // TODO: сделать анимацию переключения режимов - объединить в одном контейнере
  if (editMode) {
    return (
      <header className="DsShell__Header editMode">
        <Button className="Cancel" variant="primary" onClick={() => editModeVC.reset()}>{lang('cancel')}</Button>
        <Button className="Save" variant="primary" onClick={() => editModeVC.save()}>{lang('save')}</Button>
      </header>);
  }

  return (
    <LoadFromResources path="DsShellHeader.js"
                       schema_name={schema_name}
                       dsTitle={dsTitle}
                       dsDescription={dsDescription}
                       dsUrl={dsUrl}>
      <header className="DsShell__Header">
        <DsShellHeader schema_name={schema_name}
                       dsTitle={dsTitle}
                       dsDescription={dsDescription}
                       dsUrl={dsUrl}/>

        <DsShellTitle schema_name={schema_name}
                      dsTitle={dsTitle}
                      dsDescription={dsDescription}
                      dsUrl={dsUrl}/>
      </header>
    </LoadFromResources>);
};


let isEastPanelOpenBySwitchDataset = false;
export const EastPanelContext = React.createContext('');

interface IDsShellState {
  readonly error: string;
  readonly Module: any;
  readonly useSinglePeriod: boolean;
  westPanelEnabled: boolean;
  eastPanelEnabled: boolean;
  readonly eastPanelWidth: string; // сохраненная ширина контейнера
  readonly moveWidth: boolean;     // начинаем двигать панельку
  northPanelEnabled: boolean;
  readonly westPanelOpened: boolean;
  readonly eastPanelOpened: boolean;
  readonly northPanelOpened: boolean;
  readonly eastPanelLoading: boolean;
  readonly eastPanelLoaded: boolean;
  readonly draggingToggle: boolean;
  readonly isEastKoobControl: boolean;
  readonly dboard?: IDashboard;
  readonly displayMode: string;
  // excel cather
  readonly excelCather: boolean;
}

export class DsShell extends React.Component<IDsShellVM, IDsShellState> {
  public readonly state: IDsShellState = {
    error: null,
    Module: null,
    westPanelEnabled: false,
    eastPanelEnabled: false,
    eastPanelWidth: localStorage.getItem('preferences.eastPanelWidth') || '350',
    moveWidth: false,
    northPanelEnabled: false,
    useSinglePeriod: false,
    westPanelOpened: false,
    eastPanelOpened: isEastPanelOpenBySwitchDataset,
    northPanelOpened: false,
    eastPanelLoading: true,
    eastPanelLoaded: false,
    draggingToggle: false,
    isEastKoobControl: false,
    dboard: null,
    displayMode: '',
    //
    excelCather: false,
  };

  private _dashletsService: TransactionEntitiesService<IRawDashlet> | null = null;
  private _dsStateService: DsStateService1;

  public documentTitle = '';

  private _loadingModule: string = '';                                                              // currently loading module name
  private _panelIsKoobControl = false;

  private _messages = {
    closePanel: (event, sender, params) => {
      const pane = params.pane;
      if (pane === 'east') this.hideEastPanel();
      if (pane === 'west') this.hideWestPanel();
      if (pane === 'north') this.hideNorthPanel();
    },
  };

  public constructor(props: IDsShellVM) {
    super(props);
    if (props.state && props.dataset) {
      const ch = props.dataset.getConfigHelper();
      const disableMLP = this._checkDisableMLP();

      this.state.westPanelEnabled = !ch.getBoolValue('panel.parameters.hide') && !ch.getBoolValue('panel.metrics.hide');
      this.state.eastPanelEnabled = disableMLP && !ch.getBoolValue('panel.locations.hide');
      this.state.northPanelEnabled = !AppConfig.hasFeature('DisableMLP') && !ch.getBoolValue('panel.periods.hide');
      if (ch.getStringValue('panel.locations.type') && ch.getStringValue('panel.locations.type').indexOf('dashlet') != -1) this._panelIsKoobControl = true;
    }

    if (props.route) {
      this._routeChanged(props.route);
    }
  }

  public componentDidMount() {
    if (this.props.schema_name) {
      this._dsStateService = DsStateService1.createInstance(this.props.schemaName);
      this._dsStateService.subscribe('route dboard', this._onRouteOrDboardChange);

      // Подписка для обновления панелей из-за встраиваемого виджета включаемого в опциях
      this._dashletsService = srv.ds.DashletsService.createInstance(this.props.schema_name) as any;
      this._dashletsService.subscribeUpdates(this._onDashletsUpdated);
    }

    UrlState.subscribeUpdatesAndNotify(this.onUrlChanged);
    if (this.props.state && this.props.dataset) {
      const ch = this.props.dataset.getConfigHelper();

      const disableMLP = this._checkDisableMLP();

      this.setState({
        westPanelEnabled: !ch.getBoolValue('panel.parameters.hide') && !ch.getBoolValue('panel.metrics.hide'),
        eastPanelEnabled: disableMLP && !ch.getBoolValue('panel.locations.hide'),
        northPanelEnabled: !ch.getBoolValue('panel.periods.hide'),
      });
      if (ch.getStringValue('panel.locations.type') && ch.getStringValue('panel.locations.type').indexOf('dashlet') != -1) this._panelIsKoobControl = true;
    }


    // TODO: remove
    for (let message in this._messages) {
      MessageHub.receive(message, this._messages[message]);
    }
  }
  public componentDidUpdate(prevProps: Readonly<IDsShellVM>, prevState: Readonly<IDsShellState>) {
    if (!prevProps || this.props.route !== prevProps.route) {
      this._routeChanged(this.props.route);
    }

    if (prevState.dboard !== this.state.dboard) {
      const ch = this.props.dataset.getConfigHelper();
      const disableMLP = this._checkDisableMLP();
      this.setState({
        eastPanelEnabled: disableMLP && !ch.getBoolValue('panel.locations.hide'),
      });
    }

    //  todo зачем ? если у нас полностью пересоздается dsShell при смене schema_name
    if (this.props.schema_name !== prevProps.schema_name) {
      if (this._dsStateService) {
        this._dsStateService.unsubscribe(this._onRouteOrDboardChange);
        this._dsStateService.release();
        this._dsStateService = null;
      }

      if (this._dashletsService) {                                                                  // Подписка для обновления панелей из-за встраемого виджета включаемого в опциях
        this._dashletsService.unsubscribe(this._onDashletsUpdated);
        this._dashletsService.release();
        this._dashletsService = null;
      }

      if (this.props.schema_name) {
        this._dsStateService = DsStateService1.createInstance(this.props.schemaName);
        this._dsStateService.subscribe('route dboard', this._onRouteOrDboardChange);

        this._dashletsService = srv.ds.DashletsService.createInstance(this.props.schema_name) as any;
        this._dashletsService.subscribeUpdates(this._onDashletsUpdated);
      }
    }
  }
  public componentWillUnmount() {
    if (this._dsStateService) {
      this._dsStateService.unsubscribe(this._onRouteOrDboardChange);
      this._dsStateService.release();
      this._dsStateService = null;
    }
    if (this._dashletsService) {
      this._dashletsService.unsubscribe(this._onDashletsUpdated);
      this._dashletsService.release();
      this._dashletsService = null;
    }
    UrlState.unsubscribe(this.onUrlChanged);
    for (let message in this._messages) {
      MessageHub.off(message, this._messages[message]);
    }
  }

  private _onDashletsUpdated = (model: any) => {
    if (model.loading) return;
    if (this.props.loading) return;

    try {
      const ch = this.props.dataset.getConfigHelper();
      const disableMLP = this._checkDisableMLP();
      this.setState({
        eastPanelEnabled: disableMLP && !ch.getBoolValue('panel.locations.hide'),
      });
    } catch (err) {
      console.error(err);
      debugger;
    }
  }

  private _checkDisableMLP = (): boolean => {
    const dashletInPanel = this._checkDashletInPanel();
    return (AppConfig.hasFeature('DisableMLP')) ? (!dashletInPanel) ? false : true : true;
  }

  private _checkDashletInPanel = (): boolean => {
    let dashletInPanel: boolean = false;

    const panelLocationsType = this.props.state?.getDataset()?.getConfigHelper()?.getStringValue('panel.locations.type');
    const stateDashletsIds = this.props.state?.getModel().dboard?.getDashes()?.map(d => d.id) || [];
    const dashlets = this._dashletsService?.getModel() || [];

    const dashes = stateDashletsIds.filter((id) => {
      const dash = dashlets.find((d) => String(d.id) == String(id));
      if (dash && dash?.config?.options?.includes('EastPanel')) return dash;
    });

    const checkPanel = panelLocationsType?.match(/^dashlet\((\d+)\)$/);
    dashletInPanel = (!!checkPanel || dashes.length > 0);

    return dashletInPanel;
  }

  private _applyModuleOpts = (customModuleOpts: any): void => {
    let moduleOpts: any = {
      useSinglePeriod: false,
      metricsPanel: true,
      locationsPanel: true,
      periodsPanel: true,
      ...customModuleOpts,
    };

    const ch = this.props.dataset.getConfigHelper();
    const disableMLP = this._checkDisableMLP();

    const westPanelEnabled = !ch.getBoolValue('panel.parameters.hide') && !ch.getBoolValue('panel.metrics.hide');
    const eastPanelEnabled = disableMLP && !ch.getBoolValue('panel.locations.hide');
    const northPanelEnabled = !AppConfig.hasFeature('DisableMLP') && !ch.getBoolValue('panel.periods.hide');

    this.setState({
      useSinglePeriod: moduleOpts.useSinglePeriod,
      westPanelEnabled: westPanelEnabled && moduleOpts.metricsPanel,
      eastPanelEnabled: eastPanelEnabled && moduleOpts.locationsPanel,
      northPanelEnabled: northPanelEnabled && moduleOpts.periodsPanel,
      isEastKoobControl: this._panelIsKoobControl,
    });
    if (moduleOpts.fullscreen) this.enterFullscreen(); else this.exitFillscreen();
  }

  private _routeChanged = (route: string) => {
    route = (route || '').replace(/^#/, '');

    switch (route) {    // some old-style fixes
      case 'dashboard':
        route = 'dashboards';
        break;
      case 'plots':
        route = 'trends';
        break;
    }

    if (route === '' && this.props.dataset) {
      const ds = this.props.dataset;
      const ch = ds.getConfigHelper();
      route = ch.getStringValue('startup.page', 'dashboards');
    }
    if (!route || (route === 'theme-editor' && this.props.dataset.schema_name !== 'ds_res')) {
      this.setState({Module: null});
      return;
    }

    const modulePath: string = `../ds/${route}`;
    this._loadingModule = modulePath;

    import(`../ds/${route}`).then((Module) => {
      if (modulePath !== this._loadingModule) {              // asynchronous requests guard
        return;
      }
      try {
        if ('default' in Module) {
          Module = Module.default;
        }
        this.setState({error: null, Module});

        // TODO:
        // this._applyModuleOpts(module);

        this.documentTitle = document.title = (lang(route) ? lang(route) + ' | ' : '') + AppConfig.getProjectTitle();

      } catch (err) {
        console.error(`Error creating root module: '${route}' for path: '${modulePath}'`, err);
        console.log(err.stack);
      }
    }, (err) => {
      console.error(`Error getting module: '${modulePath}'`);
      if (err.code === 'MODULE_NOT_FOUND') {
        const variants = ['dashboards', 'edt-dashboards', 'trends', 'map', 'edt-entities', 'resources', 'dbg-vizel', 'theme-editor'].map(r => ({r, d: strDist(r, route)})).filter(({d}) => d > 0.8).sort((a, b) => b.d - a.d);
        if (variants[0]) {
          UrlState.getInstance().navigate({route: variants[0].r}, {replace: true, trigger: true});
          return;
        }
      }
      this.setState({error: extractErrorMessage(err), Module: null});
    });
  }

  public resize = debounce(() => {
    const container: HTMLElement = document.querySelector('.DsShell__Body');
    if (this._moduleRef?.resize) {
      this._moduleRef?.resize(container.clientWidth, container.clientHeight);
    }

    // TODO
    // try {
    //   if (this.root() && typeof this.root().resize === 'function') this.root().resize();
    // } catch (err) {
    //   console.error('Error calling resize method on root', err);
    //   console.log(err.stack);
    // }

    // TODO:
    // [this.metricsPanel(), this.locationsPanel(), this.periodsPanel()].forEach((panel) => {
    //   try {
    //     if (panel && ('resize' in panel)) {
    //       panel.resize();
    //     }
    //   } catch (err) {
    //     console.error('Error calling resize method on panel', err);
    //     console.log(err.stack);
    //   }
    // });
  }, 150);

  public onUrlChanged = (url) => {
    let displayMode = url.displayMode || 'default';
    if (String(displayMode).indexOf('dashlet') === 0) {
      displayMode = 'dashlet';
    }
    this.setState({displayMode});
  }

  private _onRouteOrDboardChange = (model: any) => {
    if (model) {
      this.setState(() => ({
        dboard: model.dboard,
      }));
    }
  }

  //

  public toggleWestPanel = (): void => this.setState({westPanelOpened: !this.state.westPanelOpened}, this.resize);
  public toggleNorthPanel = (): void => this.setState({northPanelOpened: !this.state.northPanelOpened});
  public toggleEastPanel = (): void => this.state.eastPanelOpened ? this.hideEastPanel() : this.showEastPanel();

  public hideEastPanel  = (): void => { this.setState({eastPanelOpened: false}); isEastPanelOpenBySwitchDataset = false; this.resize(); };
  public showEastPanel = (): void => { this.setState({eastPanelOpened: true, eastPanelLoaded: true}); isEastPanelOpenBySwitchDataset = true; this.resize(); };

  public hideWestPanel = (): void => this.setState({westPanelOpened: false}, this.resize);
  public showWestPanel = (): void => this.setState({westPanelOpened: true}, this.resize);

  public hideNorthPanel = (): void => this.setState({northPanelOpened: false});

  public enterFullscreen = (): void => document.body.classList.add('ds-fullscreen');
  public exitFillscreen = (): void => document.body.classList.remove('ds-fullscreen');
  public removePreloaderForEastPanel = (status = true): void => this.setState({eastPanelLoading: !status});

  // start resize EastPanel
  private _onPointerDown = (e: React.PointerEvent<HTMLDivElement>): void => {
    const el = e.currentTarget;
    el.setPointerCapture(e.pointerId);
    e.stopPropagation();
    this.setState({moveWidth: true});
  }
  private _onPointerUp = (e: React.PointerEvent<HTMLDivElement>): void => {
    const el = e.currentTarget;
    el.releasePointerCapture(e.pointerId);
    e.stopPropagation();
    this.setState({moveWidth: false});
    localStorage.setItem('preferences.eastPanelWidth', this.state.eastPanelWidth);
  }
  private _onPointerMove = (e: React.PointerEvent<HTMLDivElement>): void => {
    if (!this.state.moveWidth) return;
    e.stopPropagation();
    e.preventDefault();
    const eastPanelWidth = String(window.innerWidth - e.pageX);
    if (+eastPanelWidth > window.innerWidth * 0.75 || +eastPanelWidth < 245) return;
    this.setState({eastPanelWidth});
  }
  private _onDragStart = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
  }
  // end resize EastPanel

  // start excel catcher
  private _onDragOver = (e): void => {
    e.stopPropagation();
    e.preventDefault();
    if (e.dataTransfer?.items?.[0]?.type !== XLSX_MIME_TYPE) return;
    if (EditModeVC.getInstance().getModel().editMode) return; // todo временно-постоянно пока есть отчеты в правой панели
    this.setState({excelCather: true});
  }
  private _onDrop = (e): void =>    e.preventDefault();

  private _onDragLeave = (e): void => {
    e.preventDefault();
    if (e.currentTarget.contains(e.relatedTarget)) return;
    this.setState({excelCather: false});
  }
  // end excel catcher

  private _moduleRef: any;

  private _setupModuleRef = (moduleRef: any) => {
    this._moduleRef = moduleRef;
    if (moduleRef?.getModuleOptions) {
      this._applyModuleOpts(moduleRef.getModuleOptions());
    }
  }

  public render() {
    const { loading, route, schemaName, state, datasetTitle, datasetDescriptionHTML, datasetUrl } = this.props;
    const { error, Module, westPanelEnabled, eastPanelEnabled, northPanelEnabled, westPanelOpened, eastPanelOpened,
            northPanelOpened, useSinglePeriod, eastPanelLoading, eastPanelLoaded, isEastKoobControl, displayMode } = this.state;
    const {eastPanelWidth, excelCather} = this.state;

    return (
      <article
        onDragOver={this._onDragOver}
        onDrop={this._onDrop}
        onDragLeave={this._onDragLeave}
        className={cn('DsShell',
          {
            error: !!(this.props.error  || error),
            westPanelOpened,
            eastPanelOpened,
            northPanelOpened,
            WestPanelOpened: westPanelOpened,
            EastPanelOpened: eastPanelOpened,
            NorthPanelOpened: northPanelOpened,
            WestPanelDisabled: !westPanelEnabled,
            EastPanelDisabled: !eastPanelEnabled,
            NorthPanelDisabled: !northPanelEnabled,
            EastPanelKoobControl: isEastKoobControl,
            [`mode-${displayMode}`]: true
          })}
               data-route={route?.replace(/^#/, '')}
               data-display-mode={displayMode}
               data-schema-name={schemaName}>
        {!!excelCather && <ExcelCatch schemaName={schemaName} onChange={() => this.setState({excelCather: false})}/>}


        <Strap>
          {!(route === '#theme-editor' && schemaName === 'ds_res') &&

          <LoadFromResources path="DsShellLeftPane.js" schema_name={schemaName}>
            <DsShellLeftPane schema_name={schemaName}/>
          </LoadFromResources>
          }
          <Strap.Body>
            <DsShell__Header schema_name={schemaName}
                             dsTitle={datasetTitle}
                             dsDescription={datasetDescriptionHTML}
                             dsUrl={datasetUrl}/>

            {!!loading &&
            <img className="DsShell__MainLoadingImage main-loading-image" src="../../../assets/logo/logo-animated.svg"/>}

            {/*<a className="toggle ToggleWestPanel"  href={void(0)} onClick={this.toggleWestPanel}>metrics</a>*/}
            {/*<a className="toggle ToggleEastPanel"  href={void(0)} onClick={this.toggleEastPanel}>locations</a>*/}
            {/*<a className="toggle ToggleNorthPanel" href={void(0)} onClick={this.toggleNorthPanel}>periods</a>*/}
            <SetTheme theme="dark">

              <aside className={cn('EastPanel', {loading: this._panelIsKoobControl && eastPanelLoading})}
                     style={{minWidth: Number(eastPanelWidth)}}>

                <div className={cn('EastPanel__Resizer', {open: eastPanelOpened})}
                   onPointerDown={this._onPointerDown}
                   onPointerMove={this._onPointerMove}
                   onPointerUp={this._onPointerUp}
                   onDragStart={this._onDragStart}/>

                <DragableA className="toggle ToggleEastPanel"
                           href={void (0)}
                           direction="vertical"
                           onClick={this.toggleEastPanel}/>

                <EastPanelContext.Provider value="dark">
                  {(eastPanelLoaded || eastPanelOpened) &&

                  <React.Suspense fallback={null}>
                    <PanelLocations schema_name={schemaName} dsStateService={state} loadingIndicatorFunc={this.removePreloaderForEastPanel}/>
                  </React.Suspense>}

                </EastPanelContext.Provider>
              </aside>

            </SetTheme>


            <aside className="NorthPanel">

              <DragableA className="toggle ToggleNorthPanel"
                         href={void(0)}
                         direction="horizontal"
                         onClick={this.toggleNorthPanel}/>

              {northPanelOpened &&
              <React.Suspense fallback={null}>
                <PanelPeriods schema_name={schemaName} dsStateService={state} useSinglePeriod={useSinglePeriod}/>
              </React.Suspense>}
            </aside>

            {/*<!-- root -->*/}
            <section className="DsShell__Body dataset-main" style={{zIndex: 1}}>

              <aside className="WestPanel">
                <DragableA className="toggle ToggleWestPanel"
                           href={void(0)}
                           direction="vertical"
                           onClick={this.toggleWestPanel} >
                  locations
                </DragableA>
                {westPanelOpened &&
                <React.Suspense fallback={null}>
                  <PanelMetrics schema_name={schemaName} dsStateService={state}/>
                </React.Suspense>}
              </aside>

              {!!(error || this.props.error) && <DsShellError error={this.props.error || error}/>}
              {!!Module && !loading &&
              <Module ref={this._setupModuleRef} dsStateService={state} schema_name={schemaName} applyModuleOpts={this._applyModuleOpts}/>}
            </section>
          </Strap.Body>
        </Strap>
      </article>);
  }
}


// Похожесть слов для поиска ошибок в URL route
const LETTERS_SIMILARITY = ['qwsz', 'vghn', 'xdfv', 'serfcx', 'wsdr', 'rdcvg', 'fthbv', 'ygbnj', 'ujko', 'hukmn', 'ijm,l', 'kop;,.', 'njk,', 'bhjm', 'iklp', 'ol;[', 'wa', 'edft', 'awdxz', 'rfgy', 'yjih', 'cfgb', 'qase', 'zsdc', 'tghu', 'asx'];
const lsHelper = (a, b) => a && b && 'a' <= a && a <= 'z' && LETTERS_SIMILARITY[a.charCodeAt(0) - 'a'.charCodeAt(0)].includes(b) ? 1 : 0;
const letterSimilarity = (l1, l2) => Math.max(l1 === l2 ? 1 : 0, 0.5 * lsHelper(l1, l2), 0.5 * lsHelper(l2, l1), 0);
const trigramSimilarity = (t1, t2) => (letterSimilarity(t1[0], t2[0]) + letterSimilarity(t1[1], t2[1]) + letterSimilarity(t1[2], t2[2])) / 3;
const sum = (xs) => xs.reduce((a, b) => a + b, 0);
const mostSimilarWithArray = (t, ts) => ts.reduce((a, tt) => Math.max(trigramSimilarity(t, tt), a), 0);
const trigramsSimilarity = (ts1, ts2) => (sum(ts1.map(t => mostSimilarWithArray(t, ts2))) + sum(ts2.map(t => mostSimilarWithArray(t, ts1)))) / (ts1.length + ts2.length);
const makeTrigrams = (s) => s.split('').map((_, i) => s.slice(i, i + 3));
const strDist = (s1, s2) => trigramsSimilarity(makeTrigrams(ruKbdToEng(s1.toLowerCase())), makeTrigrams(ruKbdToEng(s2.toLowerCase())));


export default DsShell;

