import React from 'react';
import './CreateExcelDataSource.scss';
import {IconHandleClick} from './icons';
import cn from 'classnames';
import {idifyMany, lang} from '../../../utils/utils';
import {MitoExcel} from '../Mito/MitoExcel';
import MitoDataService, {IMitoDocumentModel, IMitoSheetModel, MitoDataServiceModel} from '../Mito/MitoDataService';
import TextEditor from '../TextEditor';
import debounce from 'lodash/debounce';
import ContextMenu from '../ContextMenu/ContextMenu';
import {AlertsVC} from '../../../view-controllers/AlertsVC';
import {DataSourceInspectorService} from '../../../services/QRPCService';
import {extractErrorMessage, srv} from '@luxms/bi-core';
import {Button} from '@luxms/bi-face';
import {ExcelIcon} from '../Mito/MitoFileManager';
import CanIService from '../../../services/CanIService';
import InputTooltip from "../../../../srx/components/form/InputTooltip";

export type IMitoColumnType = 'INT' | 'STRING' | 'DOUBLE' | 'DATETIME' | 'DATE';
export type IMitoAction = {
  readonly type: 'undo' | 'rowToHeader' | 'dropColumns' | 'dropRows' | 'renameColumn' | 'cast';
  readonly columns?: string[];
  readonly rows?: number[];
  readonly columnName?: string;
  readonly newColumnName?: string;
  readonly columnType?: IMitoColumnType;
  readonly exp?: string;
};

interface ICreateExcelDataSourceState {
  readonly sourceId: string;
  readonly selectedCols: string[] | null;
  readonly selectedRows: number[] | null;
  //
  readonly documents: IMitoDocumentModel[];
  readonly sheets: IMitoSheetModel[];
  readonly activeDocument: string;
  readonly activeSheet: string;
  //
  readonly error: string;
}

interface ICreateExcelDataSourceProps {
  readonly schema_name: string | null;
  readonly onChange: (ident: string) => void;
}

export class CreateExcelDataSource extends React.Component<ICreateExcelDataSourceProps, ICreateExcelDataSourceState> {
  public readonly state: ICreateExcelDataSourceState = {
    sourceId: null,
    documents: [],
    sheets: [],
    //
    activeDocument: null,
    activeSheet: null,
    //
    selectedCols: null,
    selectedRows: null,
    //
    error: null,
  };

  private _mitoDataService: MitoDataService;


  public componentDidMount() {
    this._mitoDataService = new MitoDataService();
    this._mitoDataService.subscribeUpdatesAndNotify(this._onUpdateData);
  }

  public componentWillUnmount() {
    this._mitoDataService.unsubscribe(this._onUpdateData);

    this._mitoDataService.release();
    this._mitoDataService = null;
  }

  private _onUpdateData = (model: MitoDataServiceModel): void => {
    const {documents, sourceId, sheets, error} = model;
    const {activeDocument, activeSheet} = this.state;
    if (!activeDocument || !activeSheet) {
      const docId = documents[0]?.id ?? null;
      const sheetId = (sheets.find(sheet => (docId === sheet.documentId && sheet.index === 0)))?.title ?? null;
      this.setState({activeDocument: docId, activeSheet: sheetId, error});
    }
    this.setState({documents, sourceId, sheets, error});
  }


  private _onContextMenuFile = (e, documentId: string): void => {
    e.preventDefault();
    ContextMenu.show(e, [
      {title: 'Удалить', action: () => this._mitoDataService.dropDocument(documentId)}
    ]);
  }

  private _onUploadFiles = (files: File[]) => this._mitoDataService.addDocuments(files);

  private _onActiveSheetCodeChange = (s: string): void => {
    this._onTextEditorCodeChange.cancel();
    this._onTextEditorCodeChange(s);
  }


  private _activateDocument = (documentId: string): void => {
    const {sheets} = this.state;
    const activeSheet = sheets.find(sheet => sheet.documentId === documentId && sheet.index === 0)?.title ?? null;
    this.setState({activeDocument: documentId, activeSheet});
  }

  private _activeSheet = (sheatName: string): void => this.setState({activeSheet: sheatName});
  private _setSelectCells = (selectedCols: string[] | null, selectedRows: number[] | null): void => {
    this.setState({selectedCols, selectedRows});
  }

  private _onAction = (action: IMitoAction): void => {
    const {activeSheet, activeDocument, sheets} = this.state;
    const sheet = sheets.find(s => (s.title === activeSheet && s.documentId === activeDocument));
    const code = sheet.code;
    const q = (s: string) => `"${s}"`;
    if (!sheet && !code) return;

    const type = action.type;

    switch (type) {
      case 'undo':
        const prg = code.split('\n');
        if (prg.length > 2) this._updateCurrentCode(prg.slice(0, -1).join('\n'));
        break;
      case 'dropColumns':
        this._updateCurrentCode(null, `dropColumns(${action.columns.map(q).join(', ')})`);
        break;
      case 'dropRows':
        this._updateCurrentCode(null, `dropRows(${action.rows.join(', ')})`);
        break;
      case 'renameColumn':
        this._updateCurrentCode(null, `renameColumn("${action.columnName}", "${action.newColumnName}")`);
        break;
      case 'cast':
        if (action.columnType === 'DATE') {
          this._updateCurrentCode(null, `castWithExpr("${action.columnName}", "${action.columnType}", to_date("${action.exp}"))`);
        } else if (action.columnType === 'DATETIME') {
          this._updateCurrentCode(null, `castWithExpr("${action.columnName}", "${action.columnType}", to_datetime("${action.exp}"))`);
        } else this._updateCurrentCode(null, `cast("${action.columnName}", "${action.columnType}")`);

        break;
      case 'rowToHeader':
        const idxRow = sheet.data.rowNumbers.indexOf(action.rows[0]);
        const rowsToDrop = sheet.data.rowNumbers.slice(0, idxRow + 1);
        const oldNames: string[] = sheet.data.columns.map(col => col.name);
        const newNames = idifyMany(sheet.data.columns.map((col, idx) => sheet.data.rows[action.rows[0]]?.[idx]));
        const cod = [
          `dropRows(${rowsToDrop.map(String).join(', ')})`,
          ...oldNames.map((colName, idx) => `renameColumn("${colName}", "${newNames[idx]}")`),
        ];
        this._updateCurrentCode(null, 'begin(' + cod.join(', ') + ')');
        break;
    }

    this.setState({selectedCols: null, selectedRows: null});
  }

  private _onTextEditorCodeChange = debounce((v) => this._updateCurrentCode(v, null), 1000);


  private _updateCurrentCode(replace: string = null, append: string = null): void {
    const {activeDocument, activeSheet, sheets} = this.state;
    const sheet = sheets.find(s => (s.title === activeSheet && s.documentId === activeDocument));
    let code = sheet?.code;
    if (!code) return;

    if (replace !== null) code = replace;
    if (append !== null) code = [...code.split('\n'), append].join('\n');

    this._mitoDataService.changeCode(activeDocument, activeSheet, code);
  }

  public render() {
    const {schema_name} = this.props;
    const {selectedCols, selectedRows, error, sheets, activeDocument, activeSheet, documents, sourceId} = this.state;

    const selectSheets = sheets.filter(s => s.documentId === activeDocument);
    const selectSheet = sheets.find(s => s.documentId === activeDocument && s.title === activeSheet);

    return (
      <div className="CreateExcelDataSource">
        <MitoFileManager schema_name={schema_name}
                         sheets={sheets}
                         sourceId={sourceId}
                         files={documents}
                         activeFileId={activeDocument}
                         onUploadFiles={this._onUploadFiles}
                         onClickFile={this._activateDocument}
                         onContextMenuFile={this._onContextMenuFile}
                         onChange={(ident) => this.props.onChange(ident)} />

        <div className="MitoExcelViews">
          <div className="MitoExcelViews__Wrapper">
            <MitoPanel selectedCols={selectedCols} selectedRows={selectedRows} onAction={this._onAction}
                       code={selectSheet?.code ?? ''}/>
            <MitoExcel error={error}
                       loading={false}
                       sheets={selectSheets}
                       sheetActive={activeSheet}
                       activeSheet={this._activeSheet}
                       selectedRows={selectedRows}
                       selectedCols={selectedCols}
                       onSelectCells={this._setSelectCells}
                       onAction={this._onAction}
            />

            <div className="MitoExcelViews__CodeEdit">
              <TextEditor content={selectSheet?.code ?? ''} contentType="text" onChange={this._onActiveSheetCodeChange}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface IMitoFileManagerProps {
  readonly schema_name: string | null;
  readonly files: IMitoDocumentModel[];
  readonly activeFileId: number | string;
  readonly onUploadFiles: (files: File[]) => any;
  readonly onClickFile?: (fileId: string) => any;
  readonly onContextMenuFile?: (e: any, fileId: string) => any;
  //
  readonly sheets: IMitoSheetModel[];
  readonly sourceId: string;
  //
  readonly onChange: (ident: string) => void;
}

interface IMitoFileManagerState {
  readonly id: string;
  readonly title: string;
  readonly canGlobal: boolean;
  readonly canLocal: boolean;
  readonly saveToGlobal: boolean;
}

export class MitoFileManager extends React.Component<IMitoFileManagerProps, IMitoFileManagerState> {
  public readonly state: IMitoFileManagerState;

  public constructor(props: IMitoFileManagerProps) {
    super(props);
    this.state = {id: '', title: '', canGlobal: false, canLocal: false, saveToGlobal: false};
  }

  public componentDidMount() {
    const {schema_name} = this.props;
    const claims: string[] = schema_name ? [`C adm.data_sources`, `C ${schema_name}.data_sources`] : [`C adm.data_sources`];
    CanIService.ensure(claims);
    CanIService.subscribeUpdatesAndNotify(this._onCanIServiceUpdated);                              // TODO подписаться только на claims
  }

  public componentWillUnmount() {
    CanIService.unsubscribe(this._onCanIServiceUpdated);
  }

  private _onCanIServiceUpdated = (canI: typeof CanIService.MODEL) => {
    const {schema_name} = this.props;
    const canGlobal = canI[`C adm.data_sources`];
    const canLocal = schema_name ? canI[`C ${schema_name}.data_sources`] : false;                   // Нет атласа - создавать не можем
    if (this.state.canGlobal !== canGlobal || this.state.canLocal !== canLocal) {
      this.setState({canGlobal, canLocal});
    }
  }

  private _createDataSource = async (): Promise<void> => {
    const {schema_name, sheets, sourceId} = this.props;
    const {id, title, canGlobal, canLocal} = this.state;

    let {saveToGlobal} = this.state;
    if (!canLocal) saveToGlobal = true;                             // здесь должны быть более сложные правила
    if (!schema_name) saveToGlobal = true;                          // атлас не задан

    if (!id) {
      AlertsVC.getInstance().pushDangerAlert(lang('enter_id_field'));
      return;
    }
    if (!title) {
      AlertsVC.getInstance().pushDangerAlert(lang('enter_title_field'));
      return;
    }

    let query: string[] = [];
    // чтобы сортировать
    const copySheet = sheets.map(s => ({documentId: s.documentId, title: s.title, code: s.code}));
    copySheet.sort((a, b) => a.documentId.localeCompare(b.documentId));
    let lastDocId = null;
    for (let i = 0; i < copySheet.length; i++) {
      const sheet = copySheet[i];
      const lines = (sheet.code).split('\n');
      if (lastDocId === sheet.documentId) query = query.concat(lines.slice(1));
      else {
        query = query.concat(lines);
        lastDocId = sheet.documentId;
      }
    }
    if (!query.length) {
      AlertsVC.getInstance().pushDangerAlert(lang('select_file_upload'));
      return;
    }

    const dataSourcesService: srv.adm.DataSourcesService | srv.ds.DataSourcesService =
      saveToGlobal ?
        srv.adm.DataSourcesService.getInstance() :
        srv.ds.DataSourcesService.createInstance(schema_name);

    try {
      await dataSourcesService.whenReady();

      const sourcesList = dataSourcesService.getModel().map(entity => entity.ident);
      if (sourcesList.includes(id)) {
        AlertsVC.getInstance().pushDangerAlert(lang('source_exist'));
        return;
      }

      const ident = saveToGlobal ? id : `source://connector/${id}?atlas=${schema_name}`;
      const dataSourceId: number = await DataSourceInspectorService.createDataSource(sourceId, query.join('\n'), ident, title);
      // TODO: сделать метод reload на BaseEntitiesService
      await dataSourcesService._init();   // метод перезагрузки ???
      AlertsVC.getInstance().pushSuccessAlert(`create data source ${id}`);
      this.props.onChange(id);
    } catch (error) {
      AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(error));
    } finally {
      if (!saveToGlobal) {                                                                          // создавали локальный параметризованный сервис - надо освобождать
        dataSourcesService.release();
      }
    }
  }

  private _onDragOver = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
  }

  private _onDrop = async (event: any): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onUploadFiles(Array.from(event.dataTransfer.files));
  }

  private _uploadFile = async (event): Promise<void> => {
    this.props.onUploadFiles(Array.from(event.target.files));
  }

  public render() {
    const {files, activeFileId, onClickFile, onContextMenuFile, schema_name} = this.props;
    const {id, title, canGlobal, canLocal, saveToGlobal} = this.state;

    return (
      <div className="MitoFileManager">
        <div className="MitoFileManager__InputFile" onDragOver={this._onDragOver} onDrop={this._onDrop}>
          <label className="MitoFileManager__InputFile-Title" htmlFor="file-upload">
            <IconHandleClick/>
            <p>
              <span>Кликните</span> или перетащите файлы в это поле
            </p>
            <input hidden={true} id="file-upload" type="file" onChange={this._uploadFile} multiple={true}
                   accept=".xls,.xlsx,.csv"/>
          </label>
          <ul className="MitoFileManager__InputFile-List">
            {
              files.map(file =>
                <ExcelFile key={file.id}
                           title={file.title}
                           active={activeFileId === file.id}
                           progress={file.progress}
                           onClick={() => onClickFile(String(file.id))}
                           onContextMenu={(e) => onContextMenuFile(e, String(file.id))}/>)
            }
          </ul>
        </div>
        <div className="MitoFileManager__Form">
          <fieldset>
            <label>
              <p>ID</p>
              <InputTooltip type="text"
                            value={id}
                            autoFocus={true}
                            regexp={new RegExp(/^[a-z][a-z0-9_]*$/gi)}
                            warningText="Только латинские буквы без пробела"
                            onChange={v => this.setState({id: v})}
              />
              <small>Не должен начинаться с цифры, без пробелов</small>
            </label>
          </fieldset>
          <fieldset>
            <label>
              <p>Название</p>
              <input type="text" value={title} onChange={e => this.setState({title: e.target.value})}/>
            </label>
          </fieldset>

          {!!canGlobal && !!canLocal && !!schema_name &&
            <label style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
              <input type="checkbox" checked={saveToGlobal} onChange={(e) => this.setState({saveToGlobal: e.target.checked})}/>
              Опубликовать
            </label>}

          <Button disabled={!!!id || !!!title} onClick={this._createDataSource} className="Next">Добавить</Button>
        </div>
      </div>
    );
  }
}

interface IExcelFileProp {
  title: string;
  active: boolean;
  progress: number;
  onClick?: (e) => any;
  onContextMenu?: (e) => any;
}

const ExcelFile: React.FC<IExcelFileProp> = ({title, active, progress, onClick, onContextMenu}): JSX.Element => (
  <div className={cn('MitoFileManager-Item', {active, loading: progress < 1})} onClick={onClick}
       onContextMenu={onContextMenu}>
    {progress === 1 ?
      <ExcelIcon/> :
      <svg viewBox="-100 -100 200 200" className="MitoFileManager-Icon">
        {/* TODO: color4*/}
        <path fill="#F2F2F8" d={getSectorPath(0, 0, 80, 95, 0, Math.max(5, 360 * progress))}/>
        <text fill="#F2F2F8" fontSize="36" x="0" y="0" dominantBaseline="middle"
              textAnchor="middle">{Math.floor(progress * 100)}%
        </text>
      </svg>}
    <span className="MitoFileManager-Title">{title}</span>
  </div>);


const rad = a => (a - 90) * Math.PI / 180.0;

const polarToCartesian = (x, y, r, a) => ({
  x: x + (r * Math.cos(rad(a))),
  y: y + (r * Math.sin(rad(a))),
});

function getSectorPath(x, y, r0, r1, a1, a2) {
  const {x: sx0, y: sy0} = polarToCartesian(x, y, r0, a2),
    {x: ex0, y: ey0} = polarToCartesian(x, y, r0, a1),
    flag0 = a2 - a1 <= 180 ? 0 : 1,
    {x: sx1, y: sy1} = polarToCartesian(x, y, r1, a2), {x: ex1, y: ey1} = polarToCartesian(x, y, r1, a1),
    flag1 = a2 - a1 <= 180 ? 0 : 1;
  return `M${sx1} ${sy1}
          A${r1} ${r1} 0 ${flag1} 0 ${ex1} ${ey1}
          L${ex0} ${ey0}
          A${r0} ${r0} 1 ${flag0} 1 ${sx0} ${sy0}
          Z`;
}

interface IMitoPanelProps {
  readonly selectedCols: string[];
  readonly selectedRows: number[];
  readonly onAction: (action: IMitoAction) => any;
  readonly code: string;
}

export const MitoPanel: React.FC<IMitoPanelProps> = ({selectedCols, selectedRows, onAction, code}): JSX.Element => {
  const prg = code.split('\n'); // 1-документ, 2-страница
  const hasBegin = prg.some(s => s.startsWith('begin')); // уже есть в заголовке
  return (
    <div className="MitoExcelViews__Panel">
      <ul className="MitoExcelViews__Panel-List">

        <li className="MitoExcelViews__Panel-Item">
          <Button disabled={prg.length < 3} className="Select" size="md" variant="muted" icon={<IconUndo/>} iconType="left"
                  onClick={() => onAction({type: 'undo'})}>
            {lang('cancel')}
          </Button>
        </li>

        <li className="MitoExcelViews__Panel-Item">
          <Button className="Select" variant="muted" size="md" disabled={!(selectedCols !== null && selectedRows === null)}
                  onClick={() => onAction({type: 'dropColumns', columns: selectedCols})}
                  icon={<IconDropRow/>} iconType="left">
            {lang('mito_deleteColumn')}
          </Button>
        </li>

        <li className="MitoExcelViews__Panel-Item">
          <Button className="Select" variant="muted" size="md" disabled={!(selectedCols === null && selectedRows !== null)}
                  onClick={() => onAction({type: 'dropRows', rows: selectedRows})}
                  icon={<IconDropColumn/>} iconType="left">
            {lang('mito_deleteRow')}
          </Button>
        </li>

        <li className="MitoExcelViews__Panel-Item">
          <Button className="Select" variant="muted" size="md" disabled={!(selectedCols === null && selectedRows !== null) || hasBegin}
                  onClick={() => onAction({type: 'rowToHeader', rows: selectedRows})}
                  icon={<IconRowToHeader/>} iconType="left">
            {lang('mito_row_to_header')}
          </Button>
        </li>
      </ul>
    </div>);
};

const IconUndo = () => (
  <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill"
          d="M5.70711 1.70711C6.09763 1.31658 6.09763 0.683417 5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L0.292893 4.29289C-0.0976311 4.68342 -0.0976311 5.31658 0.292893 5.70711L4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.09763 9.31658 6.09763 8.68342 5.70711 8.29289L3.41421 6H20V11H12C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13H20C21.1046 13 22 12.1046 22 11V6C22 4.89543 21.1046 4 20 4H3.41421L5.70711 1.70711Z"
          fill="none"/>
  </svg>
);

const IconDropRow = () => (
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill"
          d="M7 2H2L2 18H5C5.55228 18 6 18.4477 6 19C6 19.5523 5.55228 20 5 20H2C0.89543 20 0 19.1046 0 18V2C0 0.89543 0.895431 0 2 0H7C8.10457 0 9 0.895431 9 2V9C9 9.55229 8.55229 10 8 10C7.44772 10 7 9.55229 7 9V2Z"
          fill="none"/>
    <path className="fill"
          d="M8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H14C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13H8Z"
          fill="none"/>
  </svg>);

const IconDropColumn = () => (
  <svg width="23" height="11" viewBox="0 0 23 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill"
          d="M2 7V2L21 2V5C21 5.55228 21.4477 6 22 6C22.5523 6 23 5.55228 23 5V2C23 0.89543 22.1046 0 21 0H2C0.89543 0 0 0.895431 0 2V7C0 8.10457 0.895431 9 2 9H9C9.55229 9 10 8.55229 10 8C10 7.44772 9.55229 7 9 7H2Z"
          fill="none"/>
    <path className="fill"
          d="M12 9C11.4477 9 11 9.44771 11 10C11 10.5523 11.4477 11 12 11H20C20.5523 11 21 10.5523 21 10C21 9.44771 20.5523 9 20 9H12Z"
          fill="none"/>
  </svg>
);

const IconRowToHeader = () => (
  <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="fill" fillRule="evenodd" clipRule="evenodd"
          d="M1 0C0.447723 0 0 0.447693 0 1V6C0 6.55231 0.447723 7 1 7H21C21.5523 7 22 6.55231 22 6V1C22 0.447693 21.5523 0 21 0H1ZM2 2V5H20V2H2Z"
          fill="none"/>
    <path className="fill"
          d="M0 10C0 9.44769 0.447723 9 1 9H21C21.5523 9 22 9.44769 22 10C22 10.5523 21.5523 11 21 11H1C0.447723 11 0 10.5523 0 10Z"
          fill="none"/>
    <path className="fill"
          d="M1 13C0.447723 13 0 13.4477 0 14C0 14.5523 0.447723 15 1 15H15C15.5523 15 16 14.5523 16 14C16 13.4477 15.5523 13 15 13H1Z"
          fill="none"/>
  </svg>
);