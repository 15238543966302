import db_relations from './services';
import { quotifySql } from '../../../utils/utils';

const getTableId = (columnId) => columnId.split('.').slice(0, 3).join('.');
const getTableIdWithoutSourceId = (columnId) => columnId.split('.').slice(1, 3).join('.');

export function dfs(fromTableId: string, toTableId: string, links: {from: string, to: string}[]): boolean {

  let visited = {};
  function rec(tableId) {
    if (visited[tableId]) {
      return false;
    }
    if (tableId == toTableId) return true;
    visited[tableId] = true;
    for (let i = 0; i < links.length; i++) {
      if (getTableId(links[i].from) === tableId) {
        if (rec(getTableId(links[i].to))) {
          return true;
        }
      }
    }
    return false;
  }
  return rec(fromTableId);
}

export function isDbRelationsConnected(tables: string[], links: {from: string, to: string}[]): boolean {
  let visited = {};

  function rec(tableId) {
    if (tableId.length === 1) return true;
    if (visited[tableId]) return false;
    visited[tableId] = true;

    for (let i = 0; i < links.length; i++) {
      if ( tables.indexOf(getTableId(links[i].to)) !== -1 && tables.indexOf(getTableId(links[i].from)) !== -1 && tables.indexOf(getTableId(links[i].to)) !== tables.indexOf(getTableId(links[i].from))) {
        if (getTableId(links[i].from) === tableId) {
          rec(getTableId(links[i].to));
        } else if (getTableId(links[i].to) === tableId) {
          rec(getTableId(links[i].from));
        }
      }
    }
    return Object.keys(visited).length === tables.length;
    for (let i = 0; i < tables.length; i ++) {
      if (Object.keys(visited).indexOf(tables[i]) === -1) {
        return false;
      }
    }
    return true;

  }

  if (tables.length > 0) return rec(tables[0]);
  //   for (let i = 0; i < tables.length; i++) {
  //     if (!rec(tables[i])) {
  //       return rec(tables?.[i + 1]);
  //     }
  //   }
  // }
  // if (rec(tables[0])) {
  //       return true;
  //     }
  return false;
  // return Object.keys(visited).length === tables.length;
}

function quotifyMany(s: string): string {
  return s.split('.').map(quotifySql).join('.');
}


function quoteSQL(str: string) {
  // или так  /(?<=["'`])\S*?(?=["'`])/
  const val = str.replace(/['"`]/g, '');
  return `'${val}'`;
}

export function formSqlExpression(tables: any[], links: {from: string, to: string}[]): string {
  let columns: string[] = Array.prototype.concat.apply([], tables.map(t =>
    t.columns.map(c => t.id.split('.').slice(1).join('.') + '.' + quoteSQL(c.name))));

  const reversedTree: {[c: string]: {[t: string]: {[s: string]: true}}} = {};
  columns.forEach(columnSTC => {                                                                    // строим дерево [column] -> [table] -> [schema] = true
    const [s, t, c] = columnSTC.split('.');
    if (!reversedTree[c]) reversedTree[c] = {};
    if (!reversedTree[c][t]) reversedTree[c][t] = {};
    if (!reversedTree[c][t][s]) reversedTree[c][t][s] = true;
  });

  const shortNames = {};                                                                            // соответствие ["schema.table.column"] -> "короткое название"
  columns.forEach(columnSTC => {
    const [s, t, c] = columnSTC.split('.');
    if (Object.keys(reversedTree[c]).length === 1 && Object.keys(reversedTree[c][t]).length === 1) { // хватит только названия столбца
      shortNames[columnSTC] = c;
      return;
    }
    if (Object.keys(reversedTree[c][t]).length === 1) {                                             // нужно название таблицы
      shortNames[columnSTC] = `${t}_${c}`;
      return;
    }
    shortNames[columnSTC] = `${s}_${t}_${c}`;                                                       // требуется полное название со схемой
  });

  // todo идет ошибка из oracle удаляю кавычки лишние
  let strColumnsAs = columns.map((str1, i) => quotifyMany(str1) + ' as ' + quotifySql(shortNames[str1])).join(',\n       ').replace(/\'/g,'');

  const allTables = tables.map(t => t.id.split('.').slice(1).join('.'));

  if (tables.length === 1) {
    return `select ${strColumnsAs}\nfrom ${allTables.map(quotifyMany).join(', ')}`;
  } else if (!columns.length) {
    return ``;
  }


  let strLinks = [];
  links.map((l, i) => {
    if (getTableId(l.from) !== getTableId(l.to) &&
          allTables.includes(getTableIdWithoutSourceId(l.from)) &&
          allTables.includes(getTableIdWithoutSourceId(l.to))) {
      const [sf, tf, cf] = l.from.split('.').slice(1);
      const [st, tt, ct] = l.to.split('.').slice(1);
      const columnFrom = [sf, tf].join('.') + '.' + quoteSQL(cf);
      const columnTo = [st, tt].join('.') + '.' + quoteSQL(ct);

      if (columns.includes(columnTo) && columns.includes(columnFrom)) {
        // здесь написана какая-то дичь
        strLinks.push(quotifyMany(columnFrom).replace(/\'/g, '') + ' = ' + quotifyMany(columnTo).replace(/\'/g, ''));
      }
    }
  });

  return `select ${strColumnsAs}
  from ${allTables.join(', ')}
  where ${strLinks.join(' AND ')}`;
}


export function CubeName(tables: string[]): any[] {
  let nameCube = [];
  let title = [];
  tables.map((t) => {
    let [topic, id, table] = t.split('.');
    nameCube.push([id, table].join('_'));
    title.push([id, table].join('_'));
  });
  return [nameCube.join('_'), title.join('_')];
}
