import createService from '../createService';
import { repo, srv } from '@luxms/bi-core';
import CanIService from '../CanIService';


export const AutoDimensionsService = createService<typeof srv.koob.DimensionsService.MODEL>(
    'AutoDimensionsService',
    ({useService, useServiceItselfWithCustomSubscription}, schema_name, source_ident, cube_name) => {
      const globalClaim = `L koob.cubes`;
      const localClaim = `L ${schema_name}.cubes`;
      const claims = schema_name ? [globalClaim, localClaim] : [globalClaim];                           // нет атласа - смотрим только глобальне

      const canI: CanIService = useServiceItselfWithCustomSubscription(CanIService, claims);

      if (claims.some(claim => canI.can(claim) === undefined)) {                                        // какой-то клэйм из нужных не загружен => дожидаемся
        canI.ensure(claims);
        return Object.assign([], {error: null, loading: true});
      }

      const globalCubes: typeof srv.koob.CubesService.MODEL = canI.can(globalClaim) ? useService(srv.koob.CubesService) : [];
      const localCubes: typeof srv.ds.CubesService.MODEL = canI.can(localClaim) ? useService(srv.ds.CubesService, schema_name) : [];

      if (globalCubes.error || localCubes.error) return Object.assign([], {
        error: globalCubes.error || localCubes.error,
        loading: false
      });
      if (globalCubes.loading || localCubes.loading) return Object.assign([], {error: null, loading: true});

      let cube: repo.koob.IRawCube = null;
      if (!localCubes.error) cube = localCubes.find(c => c.source_ident === source_ident && c.name === cube_name && !c.is_global);
      if (!cube) cube = globalCubes.find(c => c.source_ident === source_ident && c.name === cube_name);

      if (!cube) return Object.assign([], {
        error: `No cube '${cube_name}' found (with source='${source_ident}')`,
        loading: false
      });

      const isGlobal = cube.is_global !== 0;                  // undefined or === 1

      const dimensions = isGlobal ?
          useService(srv.koob.DimensionsService, source_ident, cube_name) :
          useService(srv.ds.DimensionsService, schema_name, source_ident, cube_name);

      return Object.assign(dimensions, {is_global: cube.is_global});              // сюда же дописываем is_global
    });


export default AutoDimensionsService;
